/* ----------------------------------------------------------------------------------------------------- */
/*  @ Ag-grid overrides
/* ----------------------------------------------------------------------------------------------------- */
.ag-theme-alpine .ag-root-wrapper {
    border: none;
}
.ag-theme-material {
    font-family: 'Muli,Helvetica Neue,Arial,sans-serif';
}
.ag-body-viewport {
    // overflow-x: scroll !important;
}
.ag-body-horizontal-scroll-viewport {
    // height: 12px;
}

.ag-theme-alpine {
    font-family: unset;
    --ag-header-background-color: unset;
}

.ag-theme-alpine .ag-row {
    font-size: 12px;
}

.ag-theme-alpine-dark .ag-row {
    font-size: 12px;
}

.ag-center-aligned-header .ag-header-cell-label {
    justify-content: center;
}

.ag-center-aligned-cell {
    display: flex;
    justify-content: center;
}
.ag-center-aligned-cell .ag-cell-wrapper .ag-cell-value {
    display: flex;
    justify-content: center;
}

.ag-cell-wrapper {
    display: flex;
    align-items: center;
    width: 100% !important;
}
