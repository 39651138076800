/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

// /* width */
// ::-webkit-scrollbar {
//     width: 10px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//     background: #f1f1f1;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 5px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }
