/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* FullCalendar */
// @use '@fullcalendar/core/main.css';
// @use '@fullcalendar/daygrid/main.css';
// @use '@fullcalendar/timegrid/main.css';
// @use '@fullcalendar/list/main.css';

/* Perfect scrollbar */
// @use 'perfect-scrollbar/css/perfect-scrollbar.css';

// @use '../../node_modules/@ngneat/hot-toast/src/styles/styles.scss';

/* Quill */
// @use 'quill/dist/quill.snow.css';
// @use 'quill/dist/quill.core.css';
// "./node_modules/quill/dist/quill.core.css",
// "./node_modules/quill/dist/quill.bubble.css",
// "./node_modules/quill/dist/quill.snow.css",
/* ng-select */
// @use "@ng-select/ng-select/themes/default.theme.css";
/*@use "@ng-select/ng-select/themes/material.theme.css";*/

// @use "ag-grid-community/styles/ag-grid.css";
// @use "ag-grid-community/styles/ag-theme-alpine.css";
// @use "ag-grid-community/styles/ag-theme-alpine-dark.css";
// @use "ag-grid-community/styles/ag-theme-balham.css";
// @use "ag-grid-community/styles/ag-theme-material.css";

@use 'ag-grid-community/styles/ag-grid.css';
@use 'ag-grid-community/styles/ag-theme-alpine.css';
@use 'ag-grid-community/styles/ag-theme-balham.css';
@use 'ag-grid-community/styles/ag-theme-material.css';
// @use "ag-grid-community/styles/ag-theme-alpine-dark.css";

// @use '@ngneat/hot-toast/src/styles/styles.scss';
