/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// @font-face {
//     font-family: 'Geist';
//     src: url('../assets/fonts/geist_v1_4_01/Geist-Regular.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Geist';
//     src: url('../assets/fonts/geist_v1_4_01/Geist-Bold.woff2') format('woff2');
//     font-weight: bold;
//     font-style: normal;
// }

/* -------------------------------------------------------------------------- */
/*  @ Buy & sell colors
/* -------------------------------------------------------------------------- */
.text-buy-color {
    @apply text-green-600 dark:text-green-500 #{'!important'};
}

.text-sell-color {
    @apply text-red-600 dark:text-red-500 #{'!important'};
}

.bg-buy-color {
    @apply bg-green-600 dark:bg-green-500 #{'!important'};
}

.bg-sell-color {
    @apply bg-red-600 dark:bg-red-500 #{'!important'};
}

// .brand-link-light: '#0770C3',
// .brand-link-dark: '#60A5FA',
// .chart-bg-dark: '#161A25'
// body:not(.is-mobile) ::-webkit-scrollbar{
//     width:15px;
//     height:15px;
//     background-color:transparent;
// }
// body:not(.is-mobile) ::-webkit-scrollbar:hover{
//     background-color:rgba(0,0,0,.12);
// }
// body:not(.is-mobile) ::-webkit-scrollbar-thumb{
//     border:2px solid transparent;
//     box-shadow:inset 0 0 0 12px rgba(0,0,0,.37);
//     border-radius:12px;
// }
// body:not(.is-mobile) ::-webkit-scrollbar-thumb:active{
//     box-shadow:inset 0 0 0 12px rgba(0,0,0,.54);
//     border-radius:12px;
// }
// /* Track https://www.w3schools.com/howto/howto_css_custom_scrollbar.asp*/
// ::-webkit-scrollbar-track {
//     background: #f1f1f1;
// }

// .mat-error{
//     font-size: 12px;
//     font-weight: 500;
// }
